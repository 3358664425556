/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { config } from '@fortawesome/fontawesome-svg-core';

const onClientEntry = () => {
  config.autoAddCss = false;
};

export { onClientEntry };
